
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    status: {
      type: String as () => 'upcoming' | 'running' | 'ended',
      required: true
    },
    infinite: {
      type: Boolean,
      default: false
    }
  }
})
