import Vue from 'vue'

import { ActionContext } from 'vuex'
import { RootState, CampaignState } from '../types'
import rest from '@/rest'
import {
  CampaignData, CampaignPatch, CampaignNewIn, CampaignLock, CampaignUpdateMarker, CampaignEditUsers, CampaignsLoadParams, CampaignClonePayload
} from '@/types/campaigns'
import {
  MSG_TYPE_LOCK_CAMPAIGN, MSG_TYPE_UNLOCK_CAMPAIGN, MSG_TYPE_CAMPAIGN_UPDATED, AnyCampaignMessage
} from '@/types/events'
import { Translation, CampaignSheet, TranslationConfig } from '@/types/i18n'
import { RestError } from '@/rest/errors'
import { TokenGateTestAccount } from '@/types/token_gate'
import { Campaign } from '@/Campaign'

const state: CampaignState = {
  campaigns: {},
  currentId: null,
  currentLoading: false,
  currentTranslationSheet: null,
  current: null,
  currentParent: null,
  currentChildren: [],
  loading: false,
  editLoading: false,
  locks: {},
  updates: {},
  userEdits: {},
  tokenGateTestAccounts: []
}

type CampaignActionContext = ActionContext<CampaignState, RootState >

interface ExistsPayload {
  appId: string
  slug: string
}

const mutations = {
  SET_LOADING (state: CampaignState, loading: boolean) {
    state.loading = loading
  },
  SET_EDIT_LOADING (state: CampaignState, loading: boolean) {
    state.editLoading = loading
  },
  SET_CURRENT_ID (state: CampaignState, value: string | null) {
    state.currentId = value
  },
  SET_CURRENT_LOADING (state: CampaignState, value: boolean) {
    state.currentLoading = value
  },
  SET_LIST (state: CampaignState, data: CampaignData[]) {
    state.campaigns = Object.fromEntries(data.map(item => [item.id, item]))
  },
  SET_USER_EDITS (state: CampaignState, data: CampaignEditUsers) {
    state.userEdits = data
  },
  REPLACE (state: CampaignState, data: CampaignData) {
    state.campaigns = { ...state.campaigns, [data.id]: data }
  },
  DELETE (state: CampaignState, campaignId: string) {
    const newObj = { ...state.campaigns }
    delete newObj[campaignId]
    state.campaigns = newObj
  },
  SET_CURRENT (state: CampaignState, data: CampaignData | null) {
    state.current = data
  },
  SET_CURRENT_CHILDREN (state: CampaignState, data: CampaignData[]) {
    state.currentChildren = data
  },
  ADD_CHILD_TO_CURRENT (state: CampaignState, data: CampaignData) {
    // Replace the object if it's already in the list
    const index = state.currentChildren.findIndex(item => item.id === data.id)
    if (index !== -1) {
      Vue.set(state.currentChildren, index, data)
      return
    }
    state.currentChildren = [data, ...state.currentChildren]
  },
  SET_CURRENT_PARENT (state: CampaignState, data: CampaignData | null) {
    state.currentParent = data
  },
  SET_CURRENT_SHEET (state: CampaignState, data: CampaignSheet | null) {
    state.currentTranslationSheet = data
  },
  UPDATE_CURRENT (state: CampaignState, data: CampaignPatch) {
    if (state.current === null) {
      throw new Error('Campaign not loaded')
    }
    state.current = { ...state.current, ...data }
  },
  SET_LOCK (state: CampaignState, p: {campaignId: string; lock: CampaignLock}) {
    Vue.set(state.locks, p.campaignId, p.lock)
  },
  DELETE_LOCK (state: CampaignState, campaignId: string) {
    Vue.delete(state.locks, campaignId)
  },
  SET_UPDATED (state: CampaignState, p: {campaignId: string; update: CampaignUpdateMarker}) {
    Vue.set(state.updates, p.campaignId, p.update)
    const newCampaign = state.campaigns[p.campaignId]
    if (!newCampaign) {
      return
    }
    Vue.set(state.campaigns, p.campaignId, { ...newCampaign, last_edited_by: p.update.user })
  },
  REPLACE_CURRENT_TRANSLATIONS (state: CampaignState, data: TranslationConfig) {
    if (!state.current || !state.current.i18n) {
      return
    }
    state.current = { ...state.current, i18n: data }
  },
  UPDATE_TRANSLATION_MESSAGE (state: CampaignState, p: {locale: string; messageId: string; value: string | string[]}) {
    if (!state.current || !state.current.i18n) {
      return
    }
    const translations = state.current.i18n.translations.find(
      item => item.locale.code === p.locale
    )
    if (!translations) {
      return
    }
    Vue.set(translations.messages, p.messageId, p.value)
  },
  UPDATE_LOCALE (state: CampaignState, p: {locale: string; name: string; isDefault: boolean}) {
    if (!state.current || !state.current.i18n) {
      return
    }
    const translations = state.current.i18n.translations.find(
      item => item.locale.code === p.locale
    )
    if (!translations) {
      return
    }
    Vue.set(translations.locale, 'name', p.name)
    Vue.set(state.current.i18n, 'default_locale', p.locale)
  },
  ADD_TRANSLATION (state: CampaignState, data: Translation) {
    if (!state.current?.i18n) {
      return
    }
    state.current.i18n.translations.push(data)
  },
  DELETE_TRANSLATION (state: CampaignState, locale: string) {
    if (!state.current?.i18n) {
      return
    }
    state.current.i18n.translations = state.current.i18n.translations.filter(
      t => t.locale.code !== locale
    )
    if (state.current.i18n.default_locale === locale) {
      state.current.i18n.default_locale = state.current.i18n.translations[0]?.locale.code || ''
    }
  },
  SET_TOKEN_GATE_TEST_ACCOUNTS (state: CampaignState, data: TokenGateTestAccount[]) {
    state.tokenGateTestAccounts = data
  }

}

const actions = {
  async LOAD_LIST (ctx: CampaignActionContext, params: CampaignsLoadParams = {}) {
    ctx.commit('SET_LOADING', true)
    try {
      const data = await rest.campaigns.getAllCampaigns(params)
      const edits = await rest.campaigns.getEditUsers()
      for (const campaign of data) {
        campaign.last_edited_by = edits[campaign.id]?.last_edited_by || null
        campaign.created_by = edits[campaign.id]?.created_by || null
        campaign.touched_by_current_user = edits[campaign.id]?.touched_by_current_user || false
      }
      ctx.commit('SET_LIST', data)
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },
  async LOAD_LOCKS (ctx: CampaignActionContext) {
    const locks = await rest.campaigns.getCampaignLocks()
    for (const msg of locks) {
      await ctx.dispatch('HANDLE_MESSAGE', msg)
    }
  },
  async LOAD_CURRENT (ctx: CampaignActionContext, campaignID: string) {
    ctx.commit('SET_CURRENT_ID', campaignID)
    ctx.commit('SET_CURRENT_LOADING', true)
    ctx.commit('SET_CURRENT', null)
    ctx.commit('SET_CURRENT_SHEET', null)
    const fetchCampaign = async (fetchId: string) => {
      const campaign = await rest.campaigns.getCampaign(fetchId, { include_i18n: 'true' })
      let sheet: CampaignSheet | null
      try {
        sheet = await rest.i18n.getCampaignSheet(fetchId)
      } catch (error) {
        if ((error as RestError).status !== 404) {
          throw (error)
        }
        sheet = null
      }
      return { campaign, sheet }
    }
    try {
      const current = await fetchCampaign(campaignID)
      const children = await rest.campaigns.getCampaignChildren(campaignID)
      const parent: CampaignData | null = current.campaign.parent_id ? await rest.campaigns.getCampaign(current.campaign.parent_id, {}) : null
      const testAccounts = await rest.tokenGate.getTestAccounts()

      // Ensure hasn't changed to other campaign page while loading
      if (ctx.state.currentId === campaignID) {
        ctx.commit('SET_CURRENT', current.campaign)
        ctx.commit('SET_CURRENT_SHEET', current.sheet)
        ctx.commit('SET_CURRENT_CHILDREN', children)
        ctx.commit('SET_CURRENT_PARENT', parent)
        ctx.commit('SET_TOKEN_GATE_TEST_ACCOUNTS', testAccounts)
      }
    } finally {
      if (ctx.state.currentId === campaignID) {
        ctx.commit('SET_CURRENT_LOADING', false)
      }
    }
  },
  async EDIT_CURRENT_TRANSLATION_SHEET (ctx: CampaignActionContext, data: CampaignSheet): Promise<void> {
    if (!ctx.state.currentId) {
      return
    }
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      const translations = await rest.i18n.setCampaignSheet(ctx.state.currentId, data)
      ctx.commit('REPLACE_CURRENT_TRANSLATIONS', translations)
      ctx.commit('SET_CURRENT_SHEET', data)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async REMOVE_CURRENT_TRANSLATION_SHEET (ctx: CampaignActionContext): Promise<void> {
    if (!ctx.state.currentId) {
      return
    }
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.i18n.removeCampaignSheet(ctx.state.currentId)
      ctx.commit('SET_CURRENT_SHEET', null)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async CHANGE_CURRENT_MEDIA_FOLDER (ctx: CampaignActionContext, folder: string): Promise<void> {
    if (!ctx.state.current) {
      return
    }
    await rest.campaigns.patchCampaign(ctx.state.current.id, { media_folder: folder })
    ctx.commit('UPDATE_CURRENT', { media_folder: folder })
  },
  async EDIT_CURRENT (ctx: CampaignActionContext, data: CampaignPatch) {
    const currentId = ctx.state.currentId
    ctx.commit('SET_EDIT_LOADING', true)
    // Filter out any empty newsletters
    // Check if 'newsletters' in data
    if (data.newsletters) {
      // Filter newsletters where
      // a. newsletters.locales is an empty object
      // b. all locales have neither value for form_id or addressbook_id
      data.newsletters = data.newsletters.filter(newsletter => {
        if (Object.keys(newsletter.locales).length === 0) {
          return false
        }
        return Object.values(newsletter.locales).some(locale => locale.form_id || locale.addressbook_id)
      })
    }
    const current = ctx.state.current as CampaignData
    try {
      const result = await rest.campaigns.patchCampaign(current.id, data, { include_i18n: 'true' })
      if (currentId === result.id) {
        ctx.commit('SET_CURRENT', result)
      }
      ctx.dispatch(
        'snackbar/SHOW_MESSAGE',
        { content: `${result.name} updated!`, color: 'success' },
        { root: true }
      )
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async START_CURRENT (ctx: CampaignActionContext) {
    if (!ctx.state.current) {
      return
    }
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      const result = await rest.campaigns.startCampaignNow(ctx.state.current.id)
      ctx.commit('UPDATE_CURRENT', { starts_at: result.starts_at })
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async END_CURRENT (ctx: CampaignActionContext) {
    if (!ctx.state.current) {
      return
    }
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      const result = await rest.campaigns.endCampaignNow(ctx.state.current.id)
      ctx.commit('UPDATE_CURRENT', { ends_at: result.ends_at })
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async CREATE_NEW (ctx: CampaignActionContext, data: CampaignNewIn): Promise<CampaignData> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      const result = await rest.campaigns.createCampaign(data)
      if (result.parent_id && ctx.state.current?.id === result.parent_id) {
        ctx.commit('ADD_CHILD_TO_CURRENT', result)
      } else {
        ctx.commit('SET_CURRENT', result)
      }
      return result
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async CLEAR_CURRENT (ctx: CampaignActionContext) {
    ctx.commit('SET_CURRENT', null)
    ctx.commit('SET_CURRENT_ID', null)
    ctx.commit('SET_CURRENT_CHILDREN', [])
    ctx.commit('SET_CURRENT_PARENT', null)
  },
  async LOCK_CURRENT (ctx: CampaignActionContext): Promise<void> {
    if (!ctx.state.current || !ctx.rootGetters['global/sessionId']) {
      return
    }
    await rest.campaigns.lockCampaign(ctx.state.current.id)
  },
  async UNLOCK_CURRENT (ctx: CampaignActionContext): Promise<void> {
    if (!ctx.state.current) {
      return
    }
    await rest.campaigns.unlockCampaign(ctx.state.current.id)
  },
  /**
   * Clones the given campaign and returns the new campaign.
   * If destionation ID is set then this will replace the corresponding campaign in store
   */
  async CLONE (
    ctx: CampaignActionContext,
    params: CampaignClonePayload
  ): Promise<CampaignData> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      const result = await rest.campaigns.cloneCampaign(params.id, params.options)
      ctx.commit('REPLACE', result)
      ctx.dispatch(
        'snackbar/SHOW_MESSAGE',
        {
          content: params.successMessage ||
          `${result.name} created!`,
          color: 'success'
        },
        { root: true }
      )
      if (result.parent_id && ctx.state.current?.id === result.parent_id) {
        ctx.commit('ADD_CHILD_TO_CURRENT', result)
      }
      return result
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async CHECK_SLUG_EXISTS (
    _: CampaignActionContext,
    params: ExistsPayload
  ) {
    return await rest.campaigns.slugExists(params.appId, params.slug)
  },
  async DELETE (ctx: CampaignActionContext, campaign: CampaignData): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.campaigns.deleteCampaign(campaign.id)
      ctx.commit('DELETE', campaign.id)
      ctx.dispatch(
        'snackbar/SHOW_MESSAGE',
        { content: `${campaign.name} deleted!`, color: 'default' },
        { root: true }
      )
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async HANDLE_MESSAGE (ctx: CampaignActionContext, msg: AnyCampaignMessage): Promise<void> {
    if (msg.data.type === MSG_TYPE_LOCK_CAMPAIGN) {
      ctx.commit('SET_LOCK', {
        campaignId: msg.data.campaign_id,
        lock: {
          sessionId: msg.data.session_id,
          user: msg.data.user,
          lockedAt: msg.sent_at
        }
      })
    } else if (msg.data.type === MSG_TYPE_UNLOCK_CAMPAIGN) {
      ctx.commit('DELETE_LOCK', msg.data.campaign_id)
    } else if (msg.data.type === MSG_TYPE_CAMPAIGN_UPDATED) {
      ctx.commit('SET_UPDATED', {
        campaignId: msg.data.campaign.id,
        update: {
          updatedAt: new Date(msg.data.campaign.updated_at),
          sessionId: msg.data.session_id,
          user: msg.data.user
        }
      })
    }
  }
}

const getters = {
  currentId: (state: CampaignState) => state.currentId,
  list: (state: CampaignState): Campaign[] => Object.values(state.campaigns).map(c => new Campaign(c)),
  loading: (state: CampaignState): boolean => state.loading,
  editLoading: (state: CampaignState): boolean => state.editLoading,
  current: (state: CampaignState): Campaign|null => state.current ? new Campaign(state.current) : null,
  currentLoading: (state: CampaignState) => state.currentLoading,
  currentTranslationSheetUrl: (state: CampaignState) => {
    const data = state.currentTranslationSheet
    if (!data) {
      return null
    }
    return `https://docs.google.com/spreadsheets/d/${data.doc_id}/edit?gid=${data.sheet_id}`
  },
  currentHasTranslationSheet: (state: CampaignState) => !!state.currentTranslationSheet,
  currentChildren: (state: CampaignState): Campaign[] => state.currentChildren.map(c => new Campaign(c)),
  currentParent: (state: CampaignState): Campaign|null => state.currentParent ? new Campaign(state.currentParent) : null,
  locks: (state: CampaignState) => state.locks,
  currentLock: (state: CampaignState) => (
    state.current ? state.locks[state.current.id] || null : null
  ),
  currentUpdate: (state: CampaignState) => (
    state.current ? state.updates[state.current.id] || null : null
  ),
  currentLocales: (state: CampaignState): Record<string, Translation> => {
    const ret: Record<string, Translation> = {}
    for (const item of state.current?.i18n?.translations || []) {
      ret[item.locale.code] = item
    }
    return ret
  },
  currentLocaleCodes: (state: CampaignState, getters: any): string[] => {
    return Object.keys(getters.currentLocales)
  },
  currentLocaleNames: (state: CampaignState, getters: any): [string, string][] => {
    return Object.values(getters.currentLocales).map((l: any) => [l.locale.code, l.locale.name])
  },
  currentMediaFolder: (state: CampaignState): string => {
    return state.current?.media_folder || '/'
  },
  tokenGateTestAccounts: (state: CampaignState): TokenGateTestAccount[] => state.tokenGateTestAccounts
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
