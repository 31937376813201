
import { useStore } from '@/composables/use-store'
import { CampaignNewsletter } from '@/types/campaigns'
import { computed, defineComponent } from 'vue'

let incrementingId = 0 // Generate unique temp ID for new items

export default defineComponent({
  props: {
    value: {
      type: Array as () => CampaignNewsletter[],
      required: true
    }
  },

  emits: ['input'],

  setup (props, { emit }) {
    const store = useStore()

    const newsletterLocales = computed<[string, string][]>(() => store.getters['campaigns/currentLocaleNames'])

    const addNewsletter = () => {
      emit('input', [
        ...props.value,
        {
          enabled: true,
          locales: {},
          tempId: incrementingId++,
          signup_mode: 'implicit',
          consent_text: 'You agree to receive our newsletter',
          consent_error: 'You must agree to the terms'
        }
      ])
    }

    const deleteNewsletter = (index: number) => {
      const newValue = [...props.value]
      newValue.splice(index, 1)
      emit('input', newValue)
    }

    const setNewsletterEnabled = (index: number, item: CampaignNewsletter, enabled: boolean) => {
      // Update the enabled status of the newsletter in an immutable way
      const newValue = [...props.value]
      newValue[index] = {
        ...item,
        enabled: enabled || false
      }
      emit('input', newValue)
    }

    const setNewsletterSignupMode = (index: number, item: CampaignNewsletter, signupMode: 'implicit'|'required'|'optional') => {
      // Update the signup mode of the newsletter in an immutable way
      const newValue = [...props.value]
      newValue[index] = {
        ...item,
        signup_mode: signupMode
      }
      emit('input', newValue)
    }

    const setNewsletterName = (index: number, item: CampaignNewsletter, event: string) => {
      // Update the name of the newsletter in an immutable way
      const newValue = [...props.value]
      newValue[index] = {
        ...item,
        name: event
      }
      emit('input', newValue)
    }

    const getFieldValue = (item: CampaignNewsletter, locale: string, field: string) => {
      return (item.locales[locale] || {})[field] || ''
    }

    const setNewsletterField = (index: number, locale: string, field: string, event: string) => {
      // Update the field under locale in an immutable way
      const newValue = [...props.value]
      const item = newValue[index]
      const newLocales = {
        ...item.locales,
        [locale]: {
          ...item.locales[locale],
          [field]: event
        }
      }
      newValue[index] = {
        ...item,
        locales: newLocales
      }
      emit('input', newValue)
    }

    const signupModes = [
      { value: 'implicit', text: 'Automatically sign up without consent checkbox' },
      { value: 'optional', text: 'Optionally sign up with consent checkbox' },
      { value: 'required', text: 'Require sign up with consent checkbox' }
    ]

    return {
      newsletterLocales,
      addNewsletter,
      setNewsletterEnabled,
      getFieldValue,
      setNewsletterField,
      deleteNewsletter,
      signupModes,
      setNewsletterSignupMode,
      setNewsletterName
    }
  }
})
